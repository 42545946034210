:root {
  /* Typography | Font Family */
  --fin-font-family: 'Manrope', sans-serif;
  --fin-font-feature-settings: normal;
  --fin-font-variation-settings: normal;

  /* Primary Colors */
  --fin-color-base-white: #ffffff;
  --fin-color-base-black: #0a0a0d;

  /* Gray Cool */
  --fin-color-gray-cool-25: #f9fafa;
  --fin-color-gray-cool-50: #f3f3f4;
  --fin-color-gray-cool-100: #e7e8ea;
  --fin-color-gray-cool-200: #dcdde1;
  --fin-color-gray-cool-300: #afb0ba;
  --fin-color-gray-cool-400: #989aa6;
  --fin-color-gray-cool-500: #989aa6; /* Default */
  --fin-color-gray-cool-600: #6a6d7f;
  --fin-color-gray-cool-700: #606271;
  --fin-color-gray-cool-800: #484c62;
  --fin-color-gray-cool-900: #303344;

  /* Primary Light */
  --fin-color-primary-light-25: #ffe8f0;
  --fin-color-primary-light-50: #ffd2e1;
  --fin-color-primary-light-100: #f9bbd0;
  --fin-color-primary-light-200: #ee99b6;
  --fin-color-primary-light-300: #ed6d9a;
  --fin-color-primary-light-400: #f03b7a;
  --fin-color-primary-light-500: #e3004f; /* Default */
  --fin-color-primary-light-600: #cf044b;
  --fin-color-primary-light-700: #b2023f;
  --fin-color-primary-light-800: #900233;
  --fin-color-primary-light-900: #700027;

  /* Primary Dark */
  --fin-color-primary-dark-25: #f6f6f6;
  --fin-color-primary-dark-50: #b0b0b0;
  --fin-color-primary-dark-100: #808080;
  --fin-color-primary-dark-200: #6e6d6d;
  --fin-color-primary-dark-300: #575757;
  --fin-color-primary-dark-400: #333333;
  --fin-color-primary-dark-500: #0a0a0d; /* Default */
  --fin-color-primary-dark-600: #09090d;
  --fin-color-primary-dark-700: #08080e;
  --fin-color-primary-dark-800: #02020a;
  --fin-color-primary-dark-900: #000000;

  /* Secondary Purple */
  --fin-color-secondary-purple-25: #ededff;
  --fin-color-secondary-purple-50: #dadaff;
  --fin-color-secondary-purple-100: #c4c4ff;
  --fin-color-secondary-purple-200: #dcdde1;
  --fin-color-secondary-purple-300: #8282f5;
  --fin-color-secondary-purple-400: #5e5ee1;
  --fin-color-secondary-purple-500: #4b4bc8; /* Default */
  --fin-color-secondary-purple-600: #3434ac;
  --fin-color-secondary-purple-700: #2d2d90;
  --fin-color-secondary-purple-800: #222273;
  --fin-color-secondary-purple-900: #161655;

  /* Secondary colors */
  --fin-color-gray-warm-25: #fafafa;
  --fin-color-gray-warm-50: #f3f3f4;
  --fin-color-gray-warm-100: #e7e8ea;
  --fin-color-gray-warm-200: #dcdde1;
  --fin-color-gray-warm-300: #afb0ba;
  --fin-color-gray-warm-400: #989aa6;
  --fin-color-gray-warm-500: #989aa6; // Default
  --fin-color-gray-warm-600: #6e6d6d;
  --fin-color-gray-warm-700: #575757;
  --fin-color-gray-warm-800: #6a6d7f;
  --fin-color-gray-warm-900: #606271;

  /* Logo White */
  --fin-color-logo-white-25: #ffffff;
  --fin-color-logo-white-50: #ffffff;
  --fin-color-logo-white-100: #ffffff;
  --fin-color-logo-white-200: #ffffff;
  --fin-color-logo-white-300: #ffffff;
  --fin-color-logo-white-400: #ffffff;
  --fin-color-logo-white-500: #fbfbfb; /* Default */
  --fin-color-logo-white-600: #f5f5f5;
  --fin-color-logo-white-700: #f1f0f0;
  --fin-color-logo-white-800: #dedddd;
  --fin-color-logo-white-900: #cacaca;

  /* Error */
  --fin-color-error-25: #fffbfa;
  --fin-color-error-50: #fef3f2;
  --fin-color-error-100: #fdeae4;
  --fin-color-error-200: #fcd6ca;
  --fin-color-error-300: #fabba7;
  --fin-color-error-400: #ff8c67;
  --fin-color-error-500: #f45725; /* Default */
  --fin-color-error-600: #d92d20;
  --fin-color-error-700: #b42318;
  --fin-color-error-800: #912018;
  --fin-color-error-900: #7a271a;

  /* Warning */
  --fin-color-warning-25: #fefbf1;
  --fin-color-warning-50: #fef7e0;
  --fin-color-warning-100: #fef1c1;
  --fin-color-warning-200: #fee797;
  --fin-color-warning-300: #ffc700;
  --fin-color-warning-400: #fdb022;
  --fin-color-warning-500: #ff8a00; /* Default */
  --fin-color-warning-600: #dc6803;
  --fin-color-warning-700: #b54708;
  --fin-color-warning-800: #93370d;
  --fin-color-warning-900: #7a2e0e;

  /* Gray Iron */
  --fin-color-gray-iron-25: #fefbf1;
  --fin-color-gray-iron-50: #fef7e0;
  --fin-color-gray-iron-100: #fef1c1;
  --fin-color-gray-iron-200: #fee797;
  --fin-color-gray-iron-300: #ffc700;
  --fin-color-gray-iron-400: #fdb022;
  --fin-color-gray-iron-500: #ff8a00; /* Default */
  --fin-color-gray-iron-600: #dc6803;
  --fin-color-gray-iron-700: #b54708;
  --fin-color-gray-iron-800: #93370d;
  --fin-color-gray-900: #7a2e0e;

  /* Success */
  --fin-color-success-25: #f5fbf9;
  --fin-color-success-50: #dcfff2;
  --fin-color-success-100: #bdffe7;
  --fin-color-success-200: #8af9d1;
  --fin-color-success-300: #5debb7;
  --fin-color-success-400: #4ac99b;
  --fin-color-success-500: #3aa981; /* Default */
  --fin-color-success-600: #29966f;
  --fin-color-success-700: #1e8862;
  --fin-color-success-800: #136e4d;
  --fin-color-success-900: #0c5d40;

  /* Shadow Definitions */
  --fin-shadow-1: 0px 2px 3px rgba(72, 76, 98, 0.1);
  --fin-shadow-2: 0px 2px 5px rgba(72, 76, 98, 0.16);
  --fin-shadow-3: 0px 4px 24px rgba(72, 76, 98, 0.12);
  --fin-shadow-4: 0px 4px 24px rgba(0, 0, 0, 0.12);
  --fin-shadow-5: 0px 3px 6px rgba(0, 0, 0, 0.05), 0px 11px 11px rgba(0, 0, 0, 0.04), 0px 25px 15px rgba(0, 0, 0, 0.03);
  --fin-shadow-6: 0px 12px 36px rgba(72, 76, 98, 0.2);
  --fin-shadow-7: 0px 12px 36px rgba(0, 0, 0, 0.2);
  --fin-shadow-8: 0px 18px 30px rgba(72, 76, 98, 0.5);
}
