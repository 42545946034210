fin-button-group {
  --fin-button-group-border-color: var(--fin-color-gray-cool-200);

  [tuiWrapper][data-appearance='fin-button-group-item'] {
    // Button Item Default state
    --fin-button-group-item-border-color: var(--fin-color-gray-cool-100);
    --fin-button-group-item-text-color: var(--fin-color-base-black);
    --fin-button-group-item-bg-color: var(--fin-color-base-white);
    --fin-button-group-item-hover-text-color: var(--fin-color-gray-cool-700);
    --fin-button-group-item-hover-bg-color: var(--fin-color-gray-cool-25);
    --fin-button-group-item-pressed-text-color: var(--fin-color-gray-cool-900);
    --fin-button-group-item-pressed-bg-color: var(--fin-color-gray-cool-50);
    --fin-button-group-item-focus-text-color: var(--fin-color-base-black);
    --fin-button-group-item-focus-bg-color: var(--fin-color-gray-cool-25);
    --fin-button-group-item-focus-border-color: var(--fin-color-gray-cool-200);
    --fin-button-group-item-disabled-text-color: var(--fin-color-gray-cool-300);
    --fin-button-group-item-disabled-bg-color: var(--fin-color-gray-cool-25);

    // Button Item Badge Default state
    --fin-button-group-item-count-badge-text-color: var(--fin-color-gray-cool-500);
    --fin-button-group-item-count-badge-bg-color: var(--fin-color-gray-cool-100);
    --fin-button-group-item-count-badge-hover-text-color: var(--fin-color-gray-cool-500);
    --fin-button-group-item-count-badge-hover-bg-color: var(--fin-color-gray-cool-100);
    --fin-button-group-item-count-badge-pressed-text-color: var(--fin-color-gray-cool-500);
    --fin-button-group-item-count-badge-pressed-bg-color: var(--fin-color-gray-cool-100);
    --fin-button-group-item-count-badge-focus-text-color: var(--fin-color-gray-cool-500);
    --fin-button-group-item-count-badge-focus-bg-color: var(--fin-color-gray-cool-100);
    --fin-button-group-item-count-badge-disabled-text-color: var(--fin-color-gray-cool-300);
    --fin-button-group-item-count-badge-disabled-bg-color: var(--fin-color-gray-cool-100);

    // Button Item Active/Selected state
    --fin-button-group-item-active-text-color: var(--fin-color-primary-light-500);
    --fin-button-group-item-active-bg-color: var(--fin-color-base-white);
    --fin-button-group-item-active-hover-text-color: var(--fin-color-primary-light-400);
    --fin-button-group-item-active-hover-bg-color: var(--fin-color-primary-light-25);
    --fin-button-group-item-active-pressed-text-color: var(--fin-color-primary-light-700);
    --fin-button-group-item-active-pressed-bg-color: var(--fin-color-primary-light-50);
    --fin-button-group-item-active-focus-text-color: var(--fin-color-primary-light-500);
    --fin-button-group-item-active-focus-bg-color: var(--fin-color-primary-light-25);
    --fin-button-group-item-active-focus-border-color: var(--fin-color-primary-light-200);
    --fin-button-group-item-active-disabled-text-color: var(--fin-color-gray-cool-300);
    --fin-button-group-item-active-disabled-bg-color: var(--fin-color-gray-cool-25);

    // Button Item Badge Selected state
    --fin-button-group-item-active-count-badge-text-color: var(--fin-color-primary-light-500);
    --fin-button-group-item-active-count-badge-bg-color: var(--fin-color-primary-light-100);
    --fin-button-group-item-active-count-badge-hover-text-color: var(--fin-color-primary-light-400);
    --fin-button-group-item-active-count-badge-hover-bg-color: var(--fin-color-primary-light-100);
    --fin-button-group-item-active-count-badge-pressed-text-color: var(--fin-color-primary-light-700);
    --fin-button-group-item-active-count-badge-pressed-bg-color: var(--fin-color-primary-light-100);
    --fin-button-group-item-active-count-badge-focus-text-color: var(--fin-color-primary-light-500);
    --fin-button-group-item-active-count-badge-focus-bg-color: var(--fin-color-primary-light-100);
    --fin-button-group-item-active-count-badge-disabled-text-color: var(--fin-color-gray-cool-300);
    --fin-button-group-item-active-count-badge-disabled-bg-color: var(--fin-color-gray-cool-100);
  }
}
