@use 'mixins/mixins' as fin-mixins;

tui-accordion[finAccordion] {
  & > .tui-group.t-group.tui-group_orientation_vertical {
    gap: 20px;
  }
}

.tui-group_rounded {
  tui-accordion-item[finAccordionItem] {
    border-radius: 8px !important;

    .t-header {
      border-radius: 8px;

      &.t-header_open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        transition-property: border-radius;
        transition-duration: var(--tui-duration, 0.3s);
        transition-timing-function: ease-in-out;
      }
    }
  }
}

tui-accordion-item[finAccordionItem] {
  &[data-size='s'] {
    .t-header {
      min-height: 48px !important;
      padding: 8px 24px !important;
    }

    tui-expand > .t-wrapper > .t-content {
      padding: 24px;
    }
  }

  &._has-arrow .t-title {
    margin-right: 16px !important;
  }

  .t-header {
    border-bottom: unset;
    min-height: 48px;
  }
}

[finAccordion][data-appearance='dark'] {
  [finAccordionItem] {
    .t-header {
      background: var(--fin-accordion-item-dark-header-bg-color);

      .fin-accordion-item-header-content {
        .fin-accordion-header-title {
          color: var(--fin-accordion-item-dark-title-color);
        }
      }

      & > .t-icon {
        color: var(--fin-accordion-item-dark-icon-color) !important;
      }

      &.t-header_hoverable:hover {
        background: var(--fin-accordion-item-dark-header-hover-bg-color) !important;
      }

      &.t-header_hoverable:active {
        background: var(--fin-accordion-item-dark-header-active-bg-color) !important;
      }

      .t-header_hoverable:active:focus,
      .t-header_hoverable:active:focus-visible {
        background: var(--fin-accordion-item-dark-header-focus-bg-color) !important;
        border-color: var(--fin-accordion-item-dark-header-focus-border-color) !important;
      }
    }

    &[data-size='s'] {
      .t-header {
        padding: 8px 24px !important;
      }

      tui-expand > .t-wrapper > .t-content {
        padding: 24px;
      }
    }

    & > .t-wrapper {
      &:after {
        border-color: var(--fin-accordion-item-dark-border-color);
      }

      &:hover {
        & > .t-header.t-header_hoverable {
          background: var(--fin-accordion-item-dark-header-hover-bg-color);
          cursor: pointer;
        }
      }
    }
  }
}

[finAccordion][data-appearance='light'] {
  [finAccordionItem] {
    & > .t-wrapper {
      &:after {
        border-color: var(--fin-accordion-item-light-border-color);
      }

      &:hover {
        & > .t-header.t-header_hoverable {
          background: var(--fin-accordion-item-light-header-hover-bg-color);
          cursor: pointer;
        }
      }
    }

    .t-header {
      background: var(--fin-accordion-item-light-header-bg-color);

      .fin-accordion-item-header-content {
        .fin-accordion-header-title {
          color: var(--fin-accordion-item-light-title-color);
        }
      }

      & > .t-icon {
        color: var(--fin-accordion-item-light-icon-color) !important;
      }

      &.t-header_hoverable:hover {
        background: var(--fin-accordion-item-light-header-hover-bg-color) !important;
      }

      &.t-header_hoverable:active {
        background: var(--fin-accordion-item-light-header-active-bg-color) !important;
      }

      .t-header_hoverable:active:focus,
      .t-header_hoverable:active:focus-visible {
        background: var(--fin-accordion-item-light-header-focus-bg-color) !important;
        border-color: var(--fin-accordion-item-light-header-focus-border-color) !important;
      }
    }

    &[data-size='s'] {
      .t-header {
        padding: 8px 24px !important;
      }

      tui-expand > .t-wrapper > .t-content {
        padding: 16px;
      }
    }
  }
}

[finAccordion][data-appearance='file-uploader'] {
  [finAccordionItem] {
    & > .t-wrapper {
      & > tui-expand > .t-wrapper {
        background: var(--fin-accordion-item-file-uploader-header-bg-color);

        // copied from tui header styles to match header
        transition-property: background;
        transition-duration: var(--tui-duration, 0.3s);
        transition-timing-function: ease-in-out;
      }

      &:after {
        border-color: var(--fin-accordion-item-file-uploader-border-color);
      }

      &:hover {
        & > .t-header.t-header_hoverable {
          background: var(--fin-accordion-item-file-uploader-header-hover-bg-color);
          cursor: pointer;
        }

        // Normal state for content
        & > tui-expand > .t-wrapper {
          cursor: pointer;
          background: var(--fin-accordion-item-file-uploader-header-hover-bg-color);
        }
      }

      // Hover state for content
      &:hover {
        & > tui-expand > .t-wrapper {
          background: var(--fin-accordion-item-file-uploader-header-hover-bg-color) !important;
        }
      }

      // Active/focused state for content
      &:active {
        & > tui-expand > .t-wrapper {
          background: var(--fin-accordion-item-file-uploader-header-active-bg-color) !important;
        }
      }
    }

    .t-header {
      background: var(--fin-accordion-item-file-uploader-header-bg-color);

      .fin-accordion-item-header-content {
        .fin-accordion-header-title {
          color: var(--fin-accordion-item-file-uploader-title-color);
        }
      }

      & > .t-icon {
        color: var(--fin-accordion-item-file-uploader-icon-color) !important;
      }

      &.t-header_hoverable:hover {
        background: var(--fin-accordion-item-file-uploader-header-hover-bg-color) !important;
      }

      &.t-header_hoverable:active {
        background: var(--fin-accordion-item-file-uploader-header-active-bg-color) !important;
      }

      .t-header_hoverable:active:focus,
      .t-header_hoverable:active:focus-visible {
        background: var(--fin-accordion-item-file-uploader-header-focus-bg-color) !important;
        border-color: var(--fin-accordion-item-file-uploader-header-focus-border-color) !important;
      }
    }

    &[data-size='s'] {
      .t-header {
        padding: 8px 24px !important;
      }

      tui-expand > .t-wrapper > .t-content {
        padding: 16px;
      }
    }
  }
}

[finAccordion][data-appearance='gray'] {
  [finAccordionItem] {
    & > .t-wrapper {
      & > tui-expand > .t-wrapper {
        background: var(--fin-accordion-item-gray-header-bg-color);

        // copied from tui header styles to match header
        transition-property: background;
        transition-duration: var(--tui-duration, 0.3s);
        transition-timing-function: ease-in-out;
      }

      &:after {
        border-color: var(--fin-accordion-item-gray-border-color);
      }

      &:hover {
        & > .t-header.t-header_hoverable {
          background: var(--fin-accordion-item-gray-header-hover-bg-color);
          cursor: pointer;
        }
      }
    }

    .t-header {
      background: var(--fin-accordion-item-gray-header-bg-color);

      .fin-accordion-item-header-content {
        .fin-accordion-header-title {
          color: var(--fin-accordion-item-gray-title-color);
        }
      }

      & > .t-icon {
        color: var(--fin-accordion-item-gray-icon-color) !important;
      }

      &.t-header_hoverable:hover {
        background: var(--fin-accordion-item-gray-header-hover-bg-color) !important;
      }

      &.t-header_hoverable:active {
        background: var(--fin-accordion-item-gray-header-active-bg-color) !important;
      }

      .t-header_hoverable:active:focus,
      .t-header_hoverable:active:focus-visible {
        background: var(--fin-accordion-item-gray-header-focus-bg-color) !important;
        border-color: var(--fin-accordion-item-gray-header-focus-border-color) !important;
      }
    }

    &[data-size='s'] {
      .t-header {
        padding: 8px 24px !important;
      }

      tui-expand > .t-wrapper > .t-content {
        padding: 16px;
      }
    }
  }
}

[finAccordion][data-appearance='gray-dark'] {
  [finAccordionItem] {
    & > .t-wrapper {
      & > tui-expand > .t-wrapper {
        background: var(--fin-accordion-item-gray-dark-header-bg-color);

        // copied from tui header styles to match header
        transition-property: background;
        transition-duration: var(--tui-duration, 0.3s);
        transition-timing-function: ease-in-out;
      }

      &:after {
        border-color: transparent;
      }

      &:hover {
        & > .t-header.t-header_hoverable {
          background: var(--fin-accordion-item-gray-dark-header-hover-bg-color);
          cursor: pointer;
        }
      }
    }

    .t-header {
      background: var(--fin-accordion-item-gray-dark-header-bg-color);

      .fin-accordion-item-header-content {
        .fin-accordion-header-title {
          color: var(--fin-accordion-item-gray-dark-title-color);
        }
      }

      & > .t-icon {
        color: var(--fin-accordion-item-gray-dark-icon-color) !important;
      }

      &.t-header_hoverable:hover {
        background: var(--fin-accordion-item-gray-dark-header-hover-bg-color) !important;
      }

      &.t-header_hoverable:active {
        background: var(--fin-accordion-item-gray-dark-header-active-bg-color) !important;
      }

      .t-header_hoverable:active:focus,
      .t-header_hoverable:active:focus-visible {
        background: var(--fin-accordion-item-gray-dark-header-focus-bg-color) !important;
      }
    }

    &[data-size='s'] {
      .t-header {
        padding: 8px 24px !important;
      }

      tui-expand > .t-wrapper > .t-content {
        padding: 16px;
      }
    }
  }
}

[finAccordion][data-appearance='dynamic'] {
  [finAccordionItem] {
    & > .t-wrapper {
      & > tui-expand > .t-wrapper {
        background: var(--fin-accordion-item-dynamic-header-bg-color);

        // copied from tui header styles to match header
        transition-property: background;
        transition-duration: var(--tui-duration, 0.3s);
        transition-timing-function: ease-in-out;
      }

      &:after {
        border-color: transparent;
      }

      &:hover {
        & > .t-header.t-header_hoverable {
          background: var(--fin-accordion-item-dynamic-header-hover-bg-color);
          cursor: pointer;
        }
      }
    }

    .t-header {
      background: var(--fin-accordion-item-dynamic-header-bg-color);

      .fin-accordion-item-header-content {
        .fin-accordion-header-title {
          color: var(--fin-accordion-item-dynamic-title-color);
          font-family: var(--fin-accordion-item-dynamic-font-family);
        }
      }

      & > .t-icon {
        color: var(--fin-accordion-item-dynamic-icon-color) !important;
      }

      &.t-header_hoverable:hover {
        background: var(--fin-accordion-item-dynamic-header-hover-bg-color) !important;
      }

      &.t-header_hoverable:active {
        background: var(--fin-accordion-item-dynamic-header-active-bg-color) !important;
      }

      .t-header_hoverable:active:focus,
      .t-header_hoverable:active:focus-visible {
        background: var(--fin-accordion-item-dynamic-header-focus-bg-color) !important;
      }
    }

    &[data-size='s'] {
      .t-header {
        padding: 8px 24px !important;
      }

      tui-expand > .t-wrapper > .t-content {
        padding: 16px;
        color: var(--fin-color-base-white);
        opacity: 0.8;
        font-family: var(--fin-accordion-item-dynamic-font-family);
      }
    }
  }
}

// --------------------------------------
// Accordion Primary Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-accordion-btn-primary'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-accordion-button-primary-bg-color),
      border-color: null,
      text-color: var(--fin-accordion-button-primary-text-color),
      hover-bg-color: var(--fin-accordion-button-primary-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-accordion-button-primary-hover-text-color),
      active-bg-color: var(--fin-accordion-button-primary-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-accordion-button-primary-active-text-color),
      focus-bg-color: var(--fin-accordion-button-primary-focus-bg-color),
      focus-border-color: var(--fin-accordion-button-primary-focus-border-color),
      focus-text-color: var(--fin-accordion-button-primary-text-color),
      disabled-bg-color: var(--fin-accordion-button-primary-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-accordion-button-primary-disabled-text-color),
    ),
    (
      border-width: 2px,
    )
  );
}

// --------------------------------------
// Accordion Icon Button Flat
// --------------------------------------
[tuiWrapper][data-appearance='fin-accordion-icon-btn-accent-flat'] {
  @include fin-mixins.button-appearance(
    (
      border-color: null,
      text-color: var(--fin-accordion-icon-button-accent-flat-icon-color),
      opacity: 0.7,
      hover-border-color: null,
      hover-text-color: var(--fin-accordion-icon-button-accent-flat-hover-icon-color),
      active-border-color: null,
      active-text-color: var(--fin-accordion-icon-button-accent-flat-active-icon-color),
      focus-border-color: var(--fin-accordion-icon-button-accent-flat-focus-border-color),
      focus-text-color: var(--fin-accordion-icon-button-accent-flat-focus-icon-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-accordion-icon-button-accent-flat-disabled-icon-color),
      disabled-opacity: 0.4,
    ),
    (
      border-width: 1px,
    )
  );
}

// --------------------------------------
// Accordion Icon Button Accent
// --------------------------------------
[tuiWrapper][data-appearance='fin-accordion-icon-btn-accent'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: var(--fin-accordion-icon-button-accent-bg-color),
      border-color: null,
      text-color: var(--fin-accordion-icon-button-accent-icon-color),
      opacity: 0.7,
      hover-bg-color: var(--fin-accordion-icon-button-accent-hover-bg-color),
      hover-border-color: null,
      hover-text-color: var(--fin-accordion-icon-button-accent-hover-icon-color),
      active-bg-color: var(--fin-accordion-icon-button-accent-active-bg-color),
      active-border-color: null,
      active-text-color: var(--fin-accordion-icon-button-accent-active-icon-color),
      focus-bg-color: var(--fin-accordion-icon-button-accent-focus-bg-color),
      focus-border-color: var(--fin-accordion-icon-button-accent-focus-border-color),
      focus-text-color: var(--fin-accordion-icon-button-accent-focus-icon-color),
      disabled-bg-color: var(--fin-accordion-icon-button-accent-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-accordion-icon-button-accent-disabled-icon-color),
      disabled-opacity: 0.4,
    ),
    (
      border-width: 1px,
    )
  );
}

// --------------------------------------
// Accordion Third Button
// --------------------------------------
[tuiWrapper][data-appearance='fin-accordion-third-btn'] {
  @include fin-mixins.button-appearance(
    (
      bg-color: null,
      border-color: var(--fin-accordion-button-third-border-color),
      text-color: var(--fin-accordion-button-third-text-color),
      hover-bg-color: var(--fin-accordion-button-third-hover-bg-color),
      hover-border-color: var(--fin-accordion-button-third-hover-border-color),
      hover-text-color: var(--fin-accordion-button-third-hover-text-color),
      active-bg-color: var(--fin-accordion-button-third-active-bg-color),
      active-border-color: var(--fin-accordion-button-third-active-border-color),
      active-text-color: var(--fin-accordion-button-third-active-text-color),
      focus-bg-color: var(--fin-accordion-button-third-focus-bg-color),
      focus-border-color: var(--fin-accordion-button-third-focus-border-color),
      focus-text-color: var(--fin-accordion-button-third-focus-text-color),
      disabled-bg-color: var(--fin-accordion-button-third-disabled-bg-color),
      disabled-border-color: null,
      disabled-text-color: var(--fin-accordion-button-third-disabled-text-color),
    ),
    (
      border-width: 1px,
    )
  );
}
