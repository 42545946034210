tui-dialog[data-appearance='funding-window-dialog'][data-size='fullscreen'] {
  & > .t-content {
    padding: 32px 64px 150px !important;
  }
}

tui-dialog[data-appearance='instrument-upload-dialog'][data-size='fullscreen'] {
  & > .t-content {
    padding: 32px 32px 122px !important;
  }
}

tui-dialog[data-appearance='customers-upload-dialog'][data-size='fullscreen'] {
  & > .t-content {
    padding: 32px 32px 122px !important;
  }
}

tui-dialog[data-appearance='configure-approval-workflow-settings-dialog'][data-size='fullscreen'] {
  & > .t-content {
    padding: 32px 32px 122px !important;
  }
}

tui-dialog[data-appearance='instrument-details-dialog'][data-size='page'] {
  min-width: 471px !important;
  width: 1094px !important;
}

tui-dialog[data-appearance='audit-trail-details-dialog'][data-size='page'] {
  & > .t-content {
    padding: 32px !important;
  }
}

.t-dialog:has(tui-dialog[data-appearance='instrument-details-dialog'][data-size='page']) {
  left: initial !important;
  box-shadow: 0 18px 30px 0 #333333a3;
}

.t-dialog:has(tui-dialog[data-appearance='instrument-details-dialog'][data-size='page']) + .t-overlay {
  opacity: 0 !important;
}
